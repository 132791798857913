<template>
  <v-container fluid class="pa-6">
    <v-row no-gutters align="center" v-if="!showDataOnly"
    >
      <v-col cols="12" md="6" align="start"
      >
        <h2>
          <v-icon :color="color" large left>{{ icon }}
          </v-icon
          >
          {{ title }} <span v-if="totalCount">({{ totalCount }})</span>
        </h2>
      </v-col
      >
      <v-col cols="12" md="6" align="end">
        <v-btn
            v-if="userCan('create-' + [routeName])"
            :class="textColor"
            large
            :to="{ name: 'create-' + [routeName] }"
        >Add new
          <v-icon right :color="color">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row
    >

    <v-data-iterator
        :items="items"
        :options="pagination"
        :items-per-page.sync="pagination.rowsPerPage"
        :server-items-length.sync="totalCount"
        :page.sync="pagination.page"
        :loading="loading"
        :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions
      }"
    >
      <template v-slot:header v-if="!showDataOnly">
        <v-toolbar dark :color="color" class="mb-1 my-4 ">
          <v-text-field
              v-model="pagination.search"
              clearable
              flat
              solo-inverted
              hide-details
              class="mx-4"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              placeholder="Search"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="12" sm="6" md="2">
              <v-btn class="mx-3" large depressed outlined dark
                     @click="filterSelected=!filterSelected">
                <v-icon left>mdi-filter-variant</v-icon>
                Filters
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-btn v-if="filterSelected" large class="mx-3" depressed outlined dark
                     @click="resetFilters">
                <v-icon left>mdi-filter-remove-outline</v-icon>
                Reset filters
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="pagination.sortDesc" mandatory>
              <v-btn large depressed :color="color" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed :color="color" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
        <v-toolbar dark :color="color" class="mb-1 my-4" v-if="filterSelected">
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
                :items="workforce_requests"
                v-model="pagination.workforce_request_id"
                label="Job position"
                item-text="name"
                item-value="id"
                clearable
                multiple
                name="Job position"
                hide-details
                solo
                flat
                solo-inverted
                dark
                class="font-weight-medium mr-3"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" :color="color">
                  <span>{{ limitSize(item.name, 20) }}</span>
                </v-chip>
                <span
                    v-if="index === 1"
                    class="text-caption"
                    :color="color"
                >
                  +{{ pagination.workforce_request_id.length - 1 }}
              </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
                :items="companies"
                flat
                solo-inverted
                v-model="pagination.client_id"
                label="Client"
                item-text="name"
                item-value="id"
                clearable
                name="Client"
                hide-details
                multiple
                solo
                dark
                class="font-weight-medium mx-3"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" :color="color">
                  <span>{{ limitSize(item.name, 20) }}</span>
                </v-chip>
                <span
                    v-if="index === 1"
                    class="text-caption"
                    :color="color"
                >
                  +{{ pagination.client_id.length - 1 }}
              </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
                :items="hrCompanies"
                v-model="pagination.company_id"
                label="HR Supplier"
                item-text="name"
                item-value="id"
                multiple
                clearable
                name="HR Supplier"
                hide-details
                solo
                flat
                solo-inverted
                dark
                class="font-weight-medium ml-3"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" :color="color">
                  <span>{{ limitSize(item.name, 20) }}</span>
                </v-chip>
                <span
                    v-if="index === 1"
                    class="text-caption"
                    :color="color"
                >
                  +{{ pagination.company_id.length - 1 }}
              </span>
              </template>
            </v-autocomplete>
          </v-col>

        </v-toolbar>

      </template>
      >
      <template v-slot:no-data>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
          >
            <v-toolbar-title>Info</v-toolbar-title>
          </v-toolbar
          >
          <v-alert
              border="bottom"
              colored-border
              :color="color"
              elevation="2"
              type="info"
          >
            No data available
          </v-alert>
        </v-card>
      </template>
      >
      <template v-slot:loading>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
          >
            <v-toolbar-title>Loading data...</v-toolbar-title>
          </v-toolbar
          >
          <v-alert border="bottom" colored-border :color="color" elevation="2">
            <v-progress-circular indeterminate color="primary"/>
          </v-alert>
        </v-card>
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col
              v-for="item in props.items"
              :key="item.name"
              class="pa-4"
              cols="12"
              sm="6"
              md="4"
              lg="4"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card class="dekra-card" :class="{ 'on-hover': hover }">
                <v-card-title class="subheading font-weight-bold">
                  <v-row align="center">
                    <v-col cols="2" v-if="item.logo_preview">
                      <v-avatar size="56" tile class="pr-2"
                      >
                        <v-img contain :src="item.logo_preview"></v-img
                        >
                      </v-avatar>
                    </v-col>
                    <v-col
                        :cols="item.logo_preview ? 8 : 10"
                        class="text-left text-truncate"
                    >
                      <!--  <span class="body-3 pr-2">#{{ item["id"] }}</span>-->
                      <span>{{ limitSize(item[keyTitle], 22) }}</span></v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              icon
                              large
                              v-bind="attrs"
                              v-on="on"
                              :to="{
                              name: 'show-' + [routeName],
                              params: { id: item.id }
                            }"
                              v-if="show && userCan('show-' + [routeName])"
                          >
                            <v-icon>mdi-eye-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>View</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item
                      v-for="(key, index) in filteredKeys"
                      :key="index"
                  >
                    <v-list-item-content
                        v-if="key"
                        :class="{
                        'primary--text': pagination.sortBy === key.value
                      }"
                    >
                      {{ key.text }}:
                    </v-list-item-content
                    >

                    <v-list-item-content
                        v-if="key"
                        class="align-end"
                        :class="{
                        'primary--text': pagination.sortBy === key.value
                      }"
                    >
                      <span v-if="key.date">{{
                          formatDate(item[key.value])
                        }}</span>
                      <span v-else>{{
                          limitSize(getItemValue(item, key.value), 22)
                        }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-actions>
                  <div class="caption mx-2" v-if="item.on_job_position">
                    {{ formatDate(item.on_job_position.started_at) }}
                  </div>
                  <div class="caption mx-2" v-if="item['date']">
                    {{ formatDate(item["date"]) }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          large
                          v-if="showActivityButton && userCan('list-access-log')"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                          activityDialog = true;
                          currentId = item.id;
                        "
                      >
                        <v-icon right>mdi-timeline-clock</v-icon>
                      </v-btn>
                    </template>
                    <span>Activity log</span>
                  </v-tooltip>
                  <v-badge
                      :content="checkNewComments(item)"
                      :value="checkNewComments(item)"
                      :color="color"
                      v-if="
                      showCommentsButton && userCan('read-comments-employee')
                    "
                      overlap
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="openCommentDialog(item)"
                            :color="item.comments_count ? color:''"
                            large
                        >
                          <v-icon>mdi-comment-text-multiple</v-icon>
                        </v-btn>
                      </template>
                      <span>Comment</span>
                    </v-tooltip>
                  </v-badge>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-if="userCan('edit-' + [routeName])"
                          large
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                          name: 'edit-' + [routeName],
                          params: { id: item.id }
                        }"
                      >
                        <v-icon right>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <template v-if="item.on_job_position">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            large
                            v-bind="attrs"
                            v-on="on"
                            v-if="userCan('terminate-contract')"
                            @click="openTerminateDialog(item)"
                        >
                          <v-icon>mdi-clipboard-text-off</v-icon>
                        </v-btn>
                      </template>
                      <span>Terminate Contract</span>
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            class="error--text"
                            large
                            v-bind="attrs"
                            v-on="on"
                            v-if="userCan('delete-' + [routeName])"
                            @click="
                            confirmDeleteDialog = true;
                            currentId = item.id;
                          "
                        >
                          <v-icon right color="error"
                          >mdi-trash-can-outline
                          </v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Delete Employee</span>
                    </v-tooltip>
                  </template>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="310">
      <v-card>
        <v-card-title class="error headline white--text"
        >Confirm Delete
        </v-card-title
        >
        <v-card-text class="body-1 pa-3"
        >Are you sure you want to delete this item?
        </v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              outlined
              large
              @click.native="confirmDeleteDialog = false"
          >
            <v-icon size="20" left>mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-btn color="error" outlined large @click.native="deleteItem">
            <v-icon size="20" left>mdi-trash-can-outline</v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <terminate-contract-dialog
        v-if="showTerminationDialog"
        :job-position.sync="jobPositionId"
        :employee-id.sync="currentId"
        :workforce-id.sync="workforceId"
        :go-to-url="goTo"
        :dialog.sync="showTerminationDialog"
        @dialog-cancel="updateTerminationDialog(false)"
        @dialog-update="updateTerminationDialog"
    ></terminate-contract-dialog>
    <comment-dialog
        v-if="commentDialog"
        :type.sync="type"
        :type-id.sync="currentId"
        :dialog.sync="commentDialog"
        @dialog-cancel="updateCommentDialog(false)"
        @dialog-update="updateCommentDialog"
    ></comment-dialog>
    <activity-log-dialog
        v-if="activityDialog"
        :namespace.sync="namespace"
        :color="color"
        :id.sync="currentId"
        :dialog.sync="activityDialog"
        @dialog-cancel="updateActivityDialog(false)"
        @dialog-update="updateActivityDialog"
    ></activity-log-dialog>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import permission from "@/mixins/permission";
import string from "@/mixins/string";
import {mapGetters} from "vuex";

export default {
  mixins: [date, permission, string],
  name: "EmployeeSimpleList",
  components: {
    TerminateContractDialog: () =>
        import("@/components/core/dialogs/TerminateContract"),
    ActivityLogDialog: () =>
        import("@/components/core/dialogs/ActivityLogDialog"),
    CommentDialog: () => import("@/components/core/dialogs/CommentDialog")
  },
  props: {
    names: {
      type: Array,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    keyTitle: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    },
    goTo: {
      type: String,
      required: false
    },
    show: {
      type: Boolean,
      default: false
    },
    showCommentsButton: {
      type: Boolean,
      default: false
    },
    showActivityButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true,
      default: "Title"
    },
    icon: {
      type: String,
      required: true,
      default: "mdi-help"
    },
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      confirmDeleteDialog: false,
      showTerminationDialog: false,
      activityDialog: false,
      commentDialog: false,
      currentId: null,
      type: "employees",
      workforceId: null,
      jobPositionId: null,
      itemsPerPageOptions: [12, 24, 48, -1],
      filterSelected: false
    };
  },
  async mounted() {
    let _this = this;
    _this.$root.$on("employee-new-comment", function (data) {
      if (_this.commentDialog) {
        this.$root.$emit("new-comment", data);
      } else {
        let foundIndex = _this.items.findIndex(x => x.id === data.affectedModel.id);
        _this.items[foundIndex].new_comments.push(data.subjectModel.id);
      }
    });
    await _this.$store.dispatch("companies/getSuppliers", {
      name: "suppliers",
      company_group: 'bmw-suppliers'
    });
    await _this.$store.dispatch("companies/getSuppliers", {
      name: "hrSuppliers",
      company_group: 'hr-suppliers'
    });
    await _this.$store.dispatch("workforce/getItems", {all: true});

  },
  computed: {
    ...mapGetters({
      companies: "companies/suppliers",
      hrCompanies: "companies/hrSuppliers",
      workforce_requests: "workforce/items"

    }),
    loading: {
      get() {
        return this.$store.getters[this.namespace + "/loading"];
      }
    },
    totalCount: {
      get() {
        return this.$store.getters[this.namespace + "/total"];
      }
    },
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/getItems", false);
      }
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + "/pagination"];
      },
      set(value) {
        this.$store.dispatch(this.namespace + "/setPagination", value);
      }
    },
    textColor() {
      return this.$route.meta.textColor || "";
    },
    color() {
      return this.$route.meta.color || "";
    },
    filteredKeys() {
      return this.names.filter(key => key.value !== this.keyTitle);
    },
    _user() {
      return JSON.parse(this.$auth.remember()) || {};
    }
  },
  created() {
    this.getData();
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    checkNewComments(item) {
      if (item.new_comments) {
        let newComments = Array.isArray(item.new_comments)
            ? item.new_comments
            : Object.values(item.new_comments);
        return newComments.length;
      }
    },
    async openCommentDialog(item) {
      this.commentDialog = true;
      this.currentId = item.id;
      let newComments = Array.isArray(item.new_comments)
          ? item.new_comments
          : Object.values(item.new_comments);
      if (newComments.length > 0) {
        await this.$store
            .dispatch("comments/seenItem", {
              comments: newComments,
              user_id: this._user.id
            })
        this.getData();
      }
    },
    openTerminateDialog(item) {
      this.jobPositionId = item.on_job_position.id;
      this.workforceId = item.on_workforce_request.id;
      this.currentId = item.id;
      this.updateTerminationDialog(true);
    },
    updateActivityDialog(dialog) {
      this.activityDialog = dialog;
    },
    updateTerminationDialog(dialog) {
      this.showTerminationDialog = dialog;
      this.getData();
    },
    updateCommentDialog(dialog) {
      if(!dialog) this.getData()
      this.commentDialog = dialog;
    },
    deleteItem() {
      const _this = this;
      _this.$store
          .dispatch(_this.namespace + "/deleteItem", this.currentId)
          .then(() => {
            this.confirmDeleteDialog = false;
            this.currentId = null;
            this.getData();
          });
    },
    async getData() {
      await this.$store.dispatch(this.namespace + "/getItems", false);
    },
    getItemValue(item, name) {
      if (name.includes(".")) {
        let nameArray = name.split(".");
        if (item[nameArray[0]] != null) {
          if (nameArray[2]) {
            return item[nameArray[0]][nameArray[1]][nameArray[2]];
          }
          return item[nameArray[0]][nameArray[1]];
        }
        return "N/A";
      }
      return item[name];
    },
    resetFilters() {
      this.pagination.workforce_request_id = null;
      this.pagination.client_id = null;
      this.pagination.company_id = null;
    }
  }
};
</script>
<style scoped>
.dekra-card {
  border-bottom: 5px solid #ef6c00;
  transition: transform 0.2s;
}

.dekra-card.on-hover {
  transform: scale(1.04);
}
</style>
